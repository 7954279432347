export class AppEditionExpireAction {
    static DeactiveTenant = 'DeactiveTenant';
    static AssignToAnotherEdition = 'AssignToAnotherEdition';
}
export class EnumDirectWorkTypes {
    static Vacation = 350;
    static Hiring = 351;
    static Transfer = 352;
}

export class EnumLeaveCategory {
    static LeaveWithPay = 50;
    static LeaveWithoutPay = 51;
    static Contracted = 52;
    static Sick = 53;
    static Maternity = 54;
    static EndOfService = 55;
}

export class EnumDeputationType {
    static internalBusinessTrip = '';
    static externalBusinessTrip = 501;
    static internalTraining = 502;
    static externalTraining = 503;
}